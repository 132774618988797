import { useEffect, useState } from 'react';
import logo from './donkmemelogo.png';
import Papa from 'papaparse';

document.title = 'DonkMeme'

function Layout() {
    const [csvData, setCsvData] = useState([]);
    const [searchAddress, setSearchAddress] = useState('');
    const [foundData, setFoundData] = useState(null);

    useEffect(() => {
      fetch('/export.csv') // Assuming export.csv is in the public folder
        .then(response => response.text())
        .then(csvText => {
          const result = Papa.parse(csvText, { header: true }).data;
          setCsvData(result);
        })
        .catch(error => console.error('Error fetching CSV:', error));
    }, []);

    const handleSearch = () => {
        const foundItem = csvData.find(item => item.Address === searchAddress);
        setFoundData(foundItem);
        console.log(foundItem)
      };
    
      const handleInputChange = (e) => {
        setSearchAddress(e.target.value.trim());
      };

    return (

        <>

        <header id="gamfi-header" className="gamfi-header-section default-header">
            <div className="menu-area menu-sticky">
                <div className="container text-center">
                    <div className="heaader-inner-area justify-content-between align-items-center" >
                        <div className="gamfi-logo-area d-flex justify-content-between align-items-center">
                            <div className="logo">
                                <a href="https://donk.meme/"><img src={logo} alt="logo" width="100" /></a>
                            </div>
                            <div className="header-menu">
                                <ul className="nav-menu">
                                    <li>
                                        <a href="https://donk.meme/">Home</a>
                                    </li>
                                    <li>
                                        <a href="https://donk.meme/sale" target="_blank" rel="noreferrer">Join Presale</a>
                                    </li>
                                    <li>
                                        <a href="https://donk.meme/">Tokenomics</a>
                                    </li>
                                    <li>
                                        <a href="https://donk.meme/" >Contact</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="gamfi-btn-area">
                            <ul>
                                <li>
                                    <a id="nav-expander" className="nav-expander bar" href="https://donk.meme/">
                                        <div className="bar">
                                            <span className="dot1"></span>
                                            <span className="dot2"></span>
                                            <span className="dot3"></span>
                                        </div>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            
            <nav className="right_menu_togle mobile-navbar-menu" id="mobile-navbar-menu">
                <div className="close-btn">
                    <a href='https://donk.meme/' id="nav-close2" className="nav-close">
                        <div className="line">
                            <span className="line1"></span>
                            <span className="line2"></span>
                        </div>
                    </a>
                </div>
                <div className="sidebar-logo mb-30">
                    <a href="https://donk.meme/"><img src={logo} alt="" /></a>
                </div>
                <ul className="nav-menu">
                    <li>
                        <a href="https://donk.meme/" className="hash">Home</a>
                    </li>
                    <li>
                        <a href="https://donk.meme/sale">Join Presale</a>
                    </li>
                    <li>
                        <a href="https://donk.meme/#tokenomics">Tokenomics</a>
                    </li>
                    <li>
                        <a href="https://donk.meme/#footer">Contact</a>
                    </li>
                </ul>
            </nav>
            
        </header>
        

        <div className="gamfi-breadcrumbs-section">
            <div className="container">
                <div className="row">
                    <div className="col-lg-5">
                        <div className="breadcrumbs-area sec-heading">
                            <div className="sub-inner mb-15">
                                <a className="breadcrumbs-link" href="https://donk.meme/">Home</a>
                                <span className="sub-title">Leaderboard</span>
                                <img className="heading-left-image" src="assets/images/icons/steps.png" alt="Steps" />
                            </div>
                            <h2 className="title mb-0">Leaderboard</h2>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <div className="leaderboard-table-list pb-140 md-pb-80 pt-55">
            <div className="container">
                <div className='w-full'>
                <input
                    type="text"
                    className='p-1'
                    style={{ width: '100%' }}
                    placeholder='Wallet Address'
                    value={searchAddress}
                    onChange={handleInputChange}
                    onKeyUp={handleSearch}
                />
                </div>
                <table>
                    <thead>
                        <tr>
                            <th># RANK</th>
                            {csvData.length > 0 && Object.keys(csvData[0]).map((key, index) => (
                                <th key={index}>{key}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                    {
                        foundData 
                    ?
                        <tr style={{ lineHeight: '50px', paddingBlock: '50px' }}>
                            <td style={{ lineHeight: '50px' }}>#</td>
                            <td style={{ lineHeight: '50px', width: '500px', overflow: 'scroll' }}>{foundData.Address}</td>
                            <td style={{ lineHeight: '50px' }}>{foundData['Amount (SOL)']}</td>
                            <td style={{ lineHeight: '50px' }}>{foundData['Amount (DONKM)']}</td>
                        </tr>
                    :
                        csvData.slice(0, 50).map((row, rowIndex) => (
                            <tr key={rowIndex}>
                                <td>{rowIndex + 1}</td>
                                {Object.values(row).map((value, index) => (
                                <td key={index} style={{ overflow: 'scroll' }}>{value}</td>
                                ))}
                            </tr>
                        ))
                    }
                    </tbody>
                </table>
            </div>
        </div>


        <div className="gamfi-footer-section" id="footer">
            <div className="footer-area" style={{paddingTop: '100px'}}>
                <div className="container">
                    <div className="sec-heading text-center">
                        <div className="sub-inner mb-52">
                            <img className="heading-right-image" src="assets/images/icons/steps2.png" alt="Steps"/>
                            <span className="sub-title white-color">Find us on Social</span>
                            <img className="heading-left-image" src="assets/images/icons/steps.png" alt="Steps"/>
                        </div>
                    </div>
                    <div className="footer-listing text-center mb-100 md-mb-70">
                        <ul className="footer-icon-list">
                            <li>
                                <a href="http://twitter.com/DonkDotMeme" target="_blank" rel="noreferrer">
                                    <i className="icon-twitter"></i>
                                </a>
                            </li>
                            <li>
                                <a href="https://t.me/donkmemesolana" target="_blank" rel="noreferrer">
                                    <i className="icon-telegram"></i>
                                </a>
                            </li>
                            <li>
                                <a href="https://donkmeme.medium.com/" target="_blank" rel="noreferrer">
                                    <i className="icon-medium"></i>
                                </a>
                            </li>
                            <li>
                                <a href="https://discord.com/invite/2dfMUSGD" target="_blank" rel="noreferrer">
                                    <i className="icon-discord"></i>
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className="text-center mb-30">
                        <a href="mailto:heehaw@donk.meme">heehaw@donk.meme</a>
                    </div>
                    <div className="footer-logo text-center mb-45">
                       <a href="https://donk.meme/">
                            <img src={logo} alt="Footer-logo" width="100" />
                       </a>
                    </div>
                    <div className="copyright-area text-center mb-0">
                        <p className="dsc mb-37">
                            Copyright © 2022. All Rights Reserved by 
                            <a target="_blank" rel='noreferrer' className="gafi" href="https://donk.meme/">DonkMeme</a>
                        </p>
                    </div>
                    <div className="scrollup text-center">
                        <a href="/">
                            <i className="icon-arrow_up"></i>
                        </a>
                    </div>
                </div>
            </div>
        </div>
 

        </>

    )
}

export default Layout;